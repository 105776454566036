$(document).on('ready', function() {
  $(".full-screen").slick({
    centerMode: true,
    centerPadding: '5%',
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    infinite: true,
   
  });
});

const mySwiper = new Swiper('.swiper-container', {
	effect: 'fade',
	fadeEffect: {
	  crossFade: true,
	},
	loop: true,
	loopAdditionalSlides: 1,
	speed: 4000,
	autoplay: {
	  delay: 5000,
	  disableOnInteraction: false,
	  waitForTransition: false,
	},
	followFinger: false,
	pagination: {
	  el: '.swiper-pagination',
	  clickable: true,
	},
  });

// var mySwiper = new Swiper('.swiper-container', {
//   effect: 'fade',
//   autoplay: {
// 		delay: 3000,
// 		stopOnLastSlide: false,
// 		disableOnInteraction: false,
// 		reverseDirection: false
// 	},
// 	navigation: {
// 		nextEl: '.swiper-button-next',
// 		prevEl: '.swiper-button-prev'
// 	},
// 	pagination: {
// 		el: '.swiper-pagination',
// 		type: 'bullets',
// 		clickable: true
// 	}
// });